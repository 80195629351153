const data = [
  {
    date: "Aug 2024",
    title: "Lab Notebook",
    subtitle: "An enterprise data recording, analysis & exporting platform for scientific research.",
    content: [
      "I designed, implemented, and deployed technology infrastructure for a material science firm in London. With a focus on information security and integrity, this platform was deployed to production on AWS and currently utilised by PhD researchers, technitians, and engineers.",
      [
        "User authentication and access control via AWS services",
        "Data recording, analysis, visualisation, and exporting",
        "Dynamic creation of new machinery for processing & characterisation",
        "Automated deployment, versioning, and testing workflows via Git",
        "Integrated with IoT devices & S3 buckets (SDK) for real-time data collection",
        "RESTful request logging and monitoring",
        "Scalable and maintainable architecture, utilising load balancers"
      ],
      "This system required the authoring of comprehensive user/technical documentation for end-users, and was created to shift from an analogue to digital workflow."
    ],
    buttons: [
      "react", "aws", "mysql", "express"
    ],
    img: "ln-preview.png"
  },
  {
    date: "Apr 2023",
    title: "GreatUniHack 2023",
    subtitle: "Website for an annual hackathon event hosted by the University of Manchester.",
    content: [
      "With over 500 applications and sponsors including [Booking.com](https://www.booking.com/) and [CapitalOne](https://www.capitalone.com/), GreatUniHack is the largest student-run hackathon in Manchester.",
      [
        "Modern, responsive and accessible design",
        "Intuative user interface with animations",
        "Secure integration with technology stack on cloud infrastructure",
        "Dynamically updated event schedule and information",
      ],
      "I was responsible for leading a team of 5 developers, with a primary role in designing and developing [the website](https://greatunihack.com/) for the 2023/2024 annual event, amongst reviewing applications and organising the event."
    ],
    buttons: [
      "react", "next", "jquery", "aws"
    ],
    img: "guh-preview.png"
  },
  {
    date: "Nov 2022",
    title: "Hackchester",
    subtitle: "Website for the Cyber Security Society at the University of Manchester.",
    content: [
      "[Hackchester](https://hackchester.org/) is a society at the University of Manchester, which hosts a variety of events and workshops for students to learn about cyber security.",

      "The society required an updated and re-designed website for the 2022/23 academic year. I was responsible for identifying the end-user requirements, producing an initial design, and developing the website with a focus on accessibility and usability.",

      "The website is used to advertise workshops, and acts as a platform for competitions where students must identify and test vulnerabilities in a secure environment."
    ],
    buttons: [
      "react", "ts"
    ],
    img: "hackchester-preview.png"
  },
  {
    date: "May 2021",
    title: "Banking Framework",
    subtitle: "A fundamental banking application, integrated with a server-side database.",
    content: [
      "This application provides the end-user with a React-built interface, which requests and sends data to server while maintaining client-side consistency.",
      [
        "Utilises custom-built real-time caching infrastructure for performance",
        "Deposit, withdraw and transfer funds between accounts",
        "Create, manage and delete a selection of three accounts types",
        "Store transaction details and logs, with different transaction codes",
        "Set overdraft limits, export logs and add transaction notes",
        "Restrict permissions to deposit, withdraw and transfer",
      ],
      "Similarly to some of my other projects, the application is fully integrated with the GTA V environment and contains sandbox-level functionality."
    ],
    buttons: [
      "react", "lua", "mysql", "ts"
    ],
    img: "banking-preview.png"
  },
  {
    date: "Dec 2020",
    title: "Medical Terminal",
    subtitle: "Lightweight, simplified and responsive interface used for patient management and lookup.",
    content: [
      "Designed for usability and customization, this system was implemented using Vue and adapted for use in FiveM's NUI architecture. A full overview can be seen below.",
      [
        "Patient lookup, creation and management",
        "Submitting referrals, prescriptions, conditions & allergies",
        "Creating, editing and submitting medical reports",
        "Dynamically update access permissions to data",
        "Appropriate input validation and sanitisation",
        "User authentication with admininstrative options",
      ],
      "All actions are performed server-side (using Lua) and queried to a database, which returns and populates data accordingly."
    ],
    buttons: [
      "vue",
      "lua",
      "mysql",
    ],
    img: "medical-preview.png",
  },
  {
    date: "Jun 2020",
    title: "MDT System",
    subtitle: "A Mobile Data Terminal used to query records on a SQL database, styled for the lore-friendly FIB in Grand Theft Auto V.",
    content: [
      "This retro-themed query interface was also built using Vue.js and jQuery. A variety of search and edit functions may be performed, queried by API calls to the database. Data is returned to the front-end with a responsive design. This terminal queries and updates information used by other scripts in the game environment.",
      [
        "Querying and flagging warrants",
        "Editing, submitting, searching and deleting reports",
        "Managing user access",
        "Editing profiles, notes, flags and convictions",
        "Querying firearms, number plates & DNA",
        "Permission levels & access restrictions",
      ],
      "Appropriate database structures were created to ensure near-instant query times, with a focus on back-end optimization.",
    ],
    buttons: [
      "vue",
      "lua",
      "mysql",
      "jquery",
    ],
    img: "mdt-preview.png",
  },
  {
    date: "Apr 2020",
    title: "Inventory Framework",
    subtitle: "An intuative and modernised implementation, which makes API calls to a SQL database using Lua.",
    content: [
      "This framework offers compatibility for item objects, which are interactive and usable within the GTA V environment. The front-end interface was built using the Vue.js. Items can be moved between storage locations (e.g. vehicles or houses), and dropped for other players to access. While providing support for interaction and movement between two inventories, this application also offers:",
      [
        "Item durability, weight and quantity",
        "Drag & drop, or use keyboard shortcuts to move items",
        "First four slots binded to 'use' the items using keyboard keys",
        "Responsive interface: hover, drag and click animations",
        "Maximum capacity & weight with stackable items",
      ],
      "Security was considered during the design process. All back-end inventory logic is performed server-side using Lua, where client updates are synchronised with the server. This ensures no reliance on the client to store data, and combats vulnerabilities such as the injected execution of server-side events.",
    ],
    buttons: [
      "vue",
      "lua",
      "jquery",
    ],
    img: "inventory-preview.png",
  }
]

export default data;