
import React from 'react';
import Header from './components/Header/index.js';
import Welcome from './components/Welcome/index.js';
import AboutMe from './components/AboutMe/index.js';
import Portfolio from './components/Portfolio/index.js';
import Footer from './components/Footer/index.js';
import './App.scss';

const App = () => {
  return (
    <div className="content">
      <Header />

      <main>
        <Welcome />
        <AboutMe />
        <Portfolio />
      </main>

      <Footer />
    </div>
  );
}

export default App;
