import React from 'react';
import './index.scss';

class Footer extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <footer>
        <span className="code">© 2019-{currentYear} Jakub Rozanski. All rights reserved.</span>
      </footer>
    );
  }
}

export default Footer;
