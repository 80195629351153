import React from 'react';
import ReactDOM from 'react-dom';
import eventBus from '../../helpers/EventBus';
import { CSSTransition } from 'react-transition-group';
import './index.scss';

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {fullscreen: false};
    this.onClick = this.onClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.nodeRef = React.createRef(null);
  }

  handleKeyDown(keyboardEvent) {
    if (keyboardEvent.code === 'Escape' && this.state.fullscreen) this.onClick();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    eventBus.on("imageAction", (opened) => {
      document.body.style.overflowY = opened ? "hidden" : "auto";
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    eventBus.remove("imageAction");
  }

  onClick() {
    this.setState((state) => {
      return {fullscreen: !state.fullscreen};
    }, () => {
      eventBus.dispatch("imageAction", this.state.fullscreen);
    });
  }

  render() {
    return (
      <div className="image" onClick={this.onClick}>
        <img src={this.props.src} alt={this.props.alt} />
        {
          ReactDOM.createPortal(
            <CSSTransition in={this.state.fullscreen} timeout={200} classNames="image-transition" nodeRef={this.nodeRef}>
              <div className="fullscreen" ref={this.nodeRef}>
                <a
                  href={this.props.src}
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hyperlink"
                >
                  <img className="full-width" src={this.props.src} alt={this.props.alt} />
                </a>
                <button className="exit">
                  ×
                </button>
              </div>
            </CSSTransition>,
            document.getElementById("portal-root")
          )
        }
      </div>
    );
  }
}

export default Image;