const data = [
  {
    label: 'About Me',
    container: '#about',
  },
  {
    label: 'Portfolio',
    container: '#portfolio',
  }
]

export default data;