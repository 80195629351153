import React from 'react';
import SvgGitHub from '../../icons/github';
import SvgLinkedIn from '../../icons/linkedin';

const ICON_SIZE = 35;

const Socials = () => {
  return (
    <ul className="socials">
      <li className="icon">
        <a href="https://github.com/distritic/" target="_blank" rel="noopener noreferrer">
          <SvgGitHub alt="GitHub" width={ICON_SIZE} viewBox="0 0 98 96" />
        </a>
      </li>
      <li className="icon">
        <a href="https://www.linkedin.com/in/jrozanski/" target="_blank" rel="noopener noreferrer">
          <SvgLinkedIn alt="LinkedIn" width={ICON_SIZE} viewBox="0 0 24 24" />
        </a>
      </li>
    </ul>
  )
}

export default Socials;