import React from 'react';
import PropData from './data.js';
import './index.scss';

class TechButton extends React.Component {
  render() {
    return (
      <div className="tech-button">
        <a 
          href={PropData[this.props.type].link} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="hyperlink"
        >
          {PropData[this.props.type].label}
        </a>
      </div>
    )
  }
}

export default TechButton;