const data = {
  vue: {
    label: "Vue.js",
    link: "https://vuejs.org/",
  },
  react: {
    label: "React",
    link: "https://reactjs.org/",
  },
  fivem: {
    label: "FiveM",
    link: "https://fivem.net/",
  },
  mysql: {
    label: "MySQL",
    link: "https://mysql.com/",
  },
  jquery: {
    label: "jQuery",
    link: "https://jquery.com/",
  },
  lua: {
    label: "Lua",
    link: "https://lua.org/",
  },
  ts: {
    label: "TypeScript",
    link: "https://www.typescriptlang.org/"
  },
  next: {
    label: "Next.js",
    link: "https://nextjs.org/"
  },
  aws: {
    label: "AWS",
    link: "https://aws.amazon.com/"
  },
  express: {
    label: "Express",
    link: "https://expressjs.com/"
  }
}

export default data;