import React from 'react';
import PortfolioData from './data.js';
import PortfolioElement from './element.js';
import { Fade } from 'react-awesome-reveal';
import './index.scss';

class Portfolio extends React.Component {
  render() {
    return (
      <section id="portfolio">
        <Fade direction="up" duration={500} delay={300} triggerOnce>
        <h2><a href="/#portfolio"><span className="code">0010</span>Portfolio</a></h2>
        <p>An overview of my work may be seen below. I strive to create modern, responsive, intuative and optimised applications. This includes both back-end and front-end code, which was all produced by me unless explicitly stated otherwise.</p>
        <div className="timeline">
          <ul>
            {PortfolioData.map((element, index) => (
              <PortfolioElement key={index} index={index} element={element} />
            ))}
          </ul>
        </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;