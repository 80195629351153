import React from 'react';
import { Fade } from 'react-awesome-reveal';
import TechButton from '../TechButton/index.js';
import Image from '../Image/index.js';
import Markdown from 'react-markdown';
import Hyperlink from '../Hyperlink/index.js';

class PortfolioElement extends React.Component {
  render() {
    return (
      <Fade direction="up" duration={500} delay={300} triggerOnce>
      <li className="timeline_element">
        <div className="timeline_element-date">
          <time className="timeline_element-date-text">
            <Fade direction="left" duration={500} delay={0} triggerOnce>
              {this.props.element.date}
            </Fade>
          </time>
        </div>

        <div className="timeline_element-contents">
          <div className="timeline_element-text">
            <h3>{this.props.element.title}
              <span>{this.props.element.subtitle}</span>
            </h3>

            <Fade direction="up" duration={500} delay={0} triggerOnce>
              {this.props.element.content.map((data, index) => {
                if (data.constructor === Array) {
                  return (
                    <ul key={index} className="list">
                      {data.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )
                } else {
                  return <Markdown components={{
                    a: Hyperlink
                  }} key={index}>{data}</Markdown>
                }
              })}
            </Fade>

            <div className="timeline_element-tech">
              {this.props.element.buttons.map((data, index) => (
                <TechButton key={index} type={data} />
              ))}
            </div>
          </div>
          <Fade direction="right" duration={500} delay={350} triggerOnce>
            <div className="timeline_element-img">
              <Image src={`${process.env.PUBLIC_URL}/assets/images/${this.props.element.img}`} alt={this.props.element.img}></Image>
            </div>
          </Fade>
        </div>
      </li>
      </Fade>
    )
  }
}

export default PortfolioElement;